@import "../../global.scss";

li {
    font-size: 12px;
    margin-right: 5px;
    padding: 7px;
    cursor: pointer;

    &.active {
        background-color: $mainColor;
        color: white;
        border-radius: 10px;
    }
}