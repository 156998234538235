@import "../../global.scss";

.intro{
    background-color: lightpink;
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    .left {
        flex: 0.5;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .imgContainer{
            width: 100%;
            aspect-ratio: 1/1;

            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: lightyellow;
            @include mobile{
                width: 700px;;
                height: 700px;
            }

            img{
                margin: auto;
                width: 90%;
                height: 70%;
                border-radius: 100%;

                @include mobile{
                    width: 50%;;
                    height: 50%;
                }
            }
        }
    }

    .right {
        flex: 0.5;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile{
                padding-left: 0;
                align-items: center;
            }

            h1{
                font-size: 60px;
                margin: 10px 0;

                @include mobile{
                    font-size: 40px;
                }
            }
            h2{
                font-size: 35px;
            }
            h3{
                font-size: 30px;
                @include mobile{
                    font-size: 20px;
                }
            }
        }
        a{
            position: absolute;
            bottom: 10px;
            color: inherit;

            .icon {
                font-size: 60px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100%{
                    opacity: 100;
                }
                50% {
                    opacity: 0;
                }
                0% {
                    opacity: 100;
                }
            }
        }
    }
}