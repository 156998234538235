@import "../../global.scss";

.portfolio{
    background-color: rgb(210, 240, 210);
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 50px;
    }

    ul {
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;
    }

    .container {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        a{
            .item {
                width: 220px;
                height: 150px;
                border-radius: 20px;
                border: 1px solid gray;
                margin: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                transition: all .5 ease;
                cursor: pointer;
                h3{
                    position: absolute;
                    font-size: 18px;
                    text-align: center;
                    color: black;
                }

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 1;
                    background-color: white;
                }

                &:hover{
                    background-color: $mainColor;
                    img {
                        opacity: 0.2;
                        z-index: 0;
                    }
                }
                
            }
        }   
    }
}