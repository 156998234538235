@import "../../global.scss";

.topbar{
    width: 100%;
    height: 70px;
    background-color: white;
    color: $mainColor; //text color
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 1s ease;

    .wrapper {
        padding: 10px 15px; //format: top-bottom padding left-right padding
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            display: flex;
            align-items: center;

            .logo {
                font-size: 30px;
                font-weight: 800;
                text-decoration: none;
                color: inherit;
                margin-right: 40px;

                @include mobile{
                    font-size: 20px;
                }
            }
        }
        .right {

            .hamburger {
                width: 30px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;

                span{
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 2s ease;
                }
            }
        }
    }

    &.active {
        background-color: $mainColor;
        color: white;
        .hamburger {
            span{
                &:first-child {
                    background-color: white;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child {
                    background-color: white;
                    transform: rotate(-45deg);
                }
            }
        }
        
    }
}