.app{
    height: 100vh;

    .sections{
        width: 100%;
        height: calc(100vh - 70px);
        background-color: lightsalmon;
        position: relative;
        top: 70px;
        scroll-behavior: smooth; //makes links to different section cause a smooth scroll instead of instant (also affects snap scroll)
        scroll-snap-type: y mandatory; //enables snap scrolling
        scrollbar-width: none; //removes scrollbar for firefox
        &::-webkit-scrollbar {
            display: none; //removes scrollbar from other browsers
        }

        > * {
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
        }
    }
}