@import "../../global.scss";

.works{
    background-color: lightseagreen;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .arrow{
        height: 50px;
        position: absolute;
        @include mobile{
            top: 90%;
        }
        cursor: pointer;


        &.left{
            left: 100px;
        }
        &.right{
            right: 100px;
            transform: rotateY(180deg);
        }
    }

    .slider{
        height: 350px;
        @include mobile{
            height: 70%;
        }
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;

        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;

            .item{
                width: 700px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                @include mobile{
                    flex-direction: column;
                }
                align-items: center;
                justify-content: center;

                .left{
                    flex: 4;
                    height: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: yellowgreen;

                    .leftContainer{
                        width: 90%;
                        height: 90%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .heading{
                            @include mobile{
                                display: flex;
                                align-items: center;
                                justify-content: space-evenly;
                                flex-direction: row;
                            }
                            .imgContainer{
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                background-color: coral;
                                display: flex;
                                align-items: center;
                                justify-content: center;
    
                                img{
                                    width: 25px;
                                }
                            }
                            h2{
                                font-size: 18px;
                                
                            }
                        }
                        p{
                            font-size: 10px;
                        }
                    }
                }
                .right{
                    flex: 8;
                    @include mobile{
                        flex: 5;
                    }
                    height: 100%;
                    width: 100%;
                    background-color: yellow;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img{
                        width: 400px;
                        transform: rotate(-10deg);
                        @include mobile{
                            width: 80%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
    
}