@import "../../global.scss";

.contact{
    background-color: lightblue;
    display: flex;
    @include mobile{
        flex-direction: column;
    }

    .left{
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            aspect-ratio: 1/1;
            width: 100%;
            border-radius: 40% 40% 10% 10%;
        }
        @include mobile{
            display: none;
        }
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2{
            text-align: center;
            font-size: 30px;
        }

        form{
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            input{
                width: 80%;
                height: 30px;
                font-size: 14px;
            }

            textarea{
                width: 80%;
                height: 200px;
                @include mobile{
                    height: 400px;
                }
                font-size: 14px;
            }

            button{
                width: 150px;
                height: 30px;
                background-color: crimson;
                color: whitesmoke;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;
            }

            span{
                color: green;
                font-weight: 500;
                text-align: center;
            }
        }
    }
}